import axios from '../../config/axios';


const apiLogin = async (payload) => {
    try {
      const result = await axios.post(`/auth/login_password`,payload)
      return result
    } catch (error) {
      return error && error.response
    }
  }

  const apiLogout = async () => {
    try {
      const result = await axios.post(`/auth/logout`,{headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }})
      return result
    } catch (error) {
      return error && error.response
    }
  }

  const apiGetAuthorizeCode = async (app_code) => {
    try {
      const result = await axios.post(
        `/auth/authorize_code/grant`,
        {app_code : app_code},
        {headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        }}
      );
      return result
    } catch (error) {
      return error && error.response
    }
  }

  const apiDeleteAuth = async () => {
    try {
      const result = await axios.delete(`/user/auth/my`,{headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }})
      return result
    } catch (error) {
      return error && error.response
    }
  }

  const apiSendForgotPassword = async (email) => {
      await axios.post(`/user/request_reset_password`, { org_email : email})
      return true
  }

  const apiCheckForgotPasswordToken = async (token) => {
    let chk = await axios.get(`/user/reset_token/` + token)
    return chk.data;
}

  const apiRedeemForgotPasswordToken = async (token, password) => {
    await axios.post(`/user/reset_password`, { token : token, password : password})
    return true
  }

  export {
    apiLogin,
    apiLogout,
    apiDeleteAuth,
    apiSendForgotPassword,
    apiCheckForgotPasswordToken,
    apiRedeemForgotPasswordToken,
    apiGetAuthorizeCode
  }