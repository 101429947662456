import React, { Component }  from 'react'
import { Alert, Card, Form, Button, ListGroup } from 'react-bootstrap';


class CompletedCourseDashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
        };
    }

    


    render() {




        return (
                <Card className="shadow">
                    <Card.Body className="ps-0 pe-0">
                        <ListGroup variant="flush">
                            <ListGroup.Item className="text-center fw-bold">ประกาศนียบัตรของฉัน</ListGroup.Item>
                            <ListGroup.Item className="text-center">คุณยังไม่มีประกาศนียบัตร</ListGroup.Item>
                        </ListGroup>
                    </Card.Body>
                </Card>

        )
    }
}

export default CompletedCourseDashboard;