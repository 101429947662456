import React, { Component }  from 'react'
import FrameHeader from '../frame/header'
import lodash from 'lodash';
import { Alert, Card, Form, Button, ListGroup } from 'react-bootstrap';
import { apiGetCourseAssignmentStudent, apiPutCourseAssignmentMy } from '../../services/api/assignment';
import Swal from 'sweetalert2'
import Vimeo from '@u-wave/react-vimeo';

class CourseAssignmentVimeo extends Component {

    constructor(props) {
        super(props);

        console.log("this.props", this.props);

        this.state = {
            assignment_id : this.props.assignment_id,
            assignment : null,
            last_time : 0
        };
    }

    loadCourseAssignment = async() => {
        console.log("this.state.assignment_id", this.state.assignment_id);
        if (this.state.assignment_id === 0)
        {
            this.setState({
                assignment : null
            });
            return;
        }

        let assignment_res = await apiGetCourseAssignmentStudent(parseInt(this.state.assignment_id));
        if (assignment_res.data.assignment_type == 'video')
        {
            this.setState({
                assignment : assignment_res.data
            });
        }
    }

    componentDidMount() {
        this.loadCourseAssignment();
    }


    componentWillReceiveProps(nextProps) {
        this.setState({ assignment_id : nextProps.assignment_id });
        this.loadCourseAssignment();
    }

    onPlay = (evt) => {
        console.log("On Play", evt)
        if (evt)
        {
            this.setState({ last_time : evt.seconds });
        }
    }

    onPause = (evt) => {
        console.log("On Pause", evt)
    }

    onSeeked = (evt) => {
        console.log("On Seeked", evt)
        if (evt)
        {
            this.setState({ last_time : evt.seconds });
        }
    }

    onUpdate = (evt) => {
        console.log("On onUpdate", evt)
        if (evt.seconds - this.state.last_time >= 5 && evt.seconds - this.state.last_time < 5.5)
        {
            this.sendToServer(this.state.last_time, evt.seconds);
            this.setState({ last_time : evt.seconds });
        }
    }

    

    sendToServer = (from, to) => {
        if (this.state.assignment.assignment_progress > 99)
            return;
        console.log("sendToServer", from, to)
        apiPutCourseAssignmentMy(this.state.assignment_id, {
            "from" : from,
            "to" : to
        })
    }

    render() {

        return (
            <>
            {
                this.state.assignment && <Vimeo 
                    video={ this.state.assignment.assignment_data.url } height="350" 
                    onPlay={this.onPlay}
                    onPause={this.onPause}
                    onSeeked={this.onSeeked}
                    onTimeUpdate={this.onUpdate}
                />
            }
            </>
        )
    }
}

export default CourseAssignmentVimeo;
