import React, { Component }  from 'react'
import FrameHeaderLeftMenu from '../frame/header_left_menu'
import { Alert, Card, Form, Button, ListGroup, ProgressBar } from 'react-bootstrap';
import { apiGetCourse } from '../../services/api/course';
import lodash from 'lodash';
import Swal from 'sweetalert2'

class ActiveCourseDashboard extends Component {

    constructor(props) {
        super(props);


        this.state = {
            my_courses : null
        };


    }

    loadMyCourse = async() => {
        let course_data = await apiGetCourse(1, 1000, [], [{ "key" : "is_course_complete", "value" : "0" }]);
        
        this.setState({ my_courses : lodash.map(course_data.data.data, function(course) {
            return {
                id : course.id,
                course_name : course.course_name,
                learning_progress : course.course_student[0].learning_progress,
                course_is_publish : course.course_is_publish,
                course_data : course.course_data,
                course_logo : course.course_logo
            }
        }) });
    }

    redirectCourse = (course) => {
        if (course.course_is_publish)
            window.location.replace('/mooc/course/' + course.id + '/detail');
        else
        {
            Swal.fire({
                icon: 'error',
                text: "วิชา " + course.course_name + " ไม่สามารถเข้าศึกษาได้ในขณะนี้"
            });
        }
    }
    componentDidMount() {
        this.loadMyCourse();
    }

    render() {

        return (
            <>
                <div className="fw-bold">
                    หลักสูตรที่กำลังศึกษาอยู่
                </div>
                {
                    (this.state.my_courses && this.state.my_courses.length == 0) && 
                    <Alert variant="info" className="text-center">
                        คุณยังไม่ลงทะเบียน กรุณากดที่เมนูคอร์สเรียนทั้งหมด เพื่อลงทะเบียน
                    </Alert>
                }
                {
                    (this.state.my_courses && this.state.my_courses.length > 0) && 
                    <div className="d-flex mt-3 flex-wrap b-center">
                        {
                            this.state.my_courses.map((course, i) =>
                                <Card className={`shadow border-secondary dashboard-course me-3 mb-3 ${!course.course_is_publish ? 'dashboard-gray' : ''}`}
                                    key={course.id}
                                >
                                    <div className="card-header" style={{
                                        ...(
                                            ( course.course_logo ) ?{
                                                backgroundImage : "url('" + process.env.REACT_APP_STORAGE_ENDPOINT + "/" + course.course_logo +"')",
                                                backgroundSize : "cover"
                                            }:
                                            {
                                                backgroundImage : "url('/top_logo.png')",
                                                backgroundSize : "contain"
                                            }
                                        )

                                    }}>

                                    </div>
                                    
                                    <Card.Body className="p-2 text-center" >
                                        {
                                            course.course_is_publish && 
                                            <>
                                            <div className="fw-bold text-primary text-name"> { course.course_name } </div>
                                            <div className="mt-3 text-end">
                                                <small onClick={() => this.redirectCourse(course) } style={{ cursor: "pointer"}} >แสดงข้อมูลเพิ่มเติม</small>
                                            </div>
                                            </>
                                            
                                        }
                                        {
                                            !course.course_is_publish && 
                                            <>
                                            <div className="fw-bold text-muted text-name"> { course.course_name } </div>

                                            <div className="mt-3 text-end">
                                                <small >หลักสูตรไม่พร้อมให้เข้าศึกษา</small>
                                            </div>
                                            </>
                                        }

                                        <div className="mb-3">
                                            <ProgressBar striped variant="danger" now={course.learning_progress} label={`${course.learning_progress}%`} />
                                        </div>
                                        <div className="text-primary progress_detail"> { course.learning_progress }% กำลังดำเนินการอยู่</div>
                                    </Card.Body>
                                </Card>
                            )
                        }

                    </div>
                }
            </>
        )
    }
}

export default ActiveCourseDashboard;