import React, { Component }  from 'react'
import { Alert, Card, Form, Button } from 'react-bootstrap';
import FrameHeader from '../frame/header';
import Swal from 'sweetalert2'
import { registerUser } from '../../services/api/resgiter'
import isEmail from 'validator/lib/isEmail';


class AuthRegisterFrm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username : "",
            password : "",
            password_re : "",
            email : "",
            firstname : "",
            lastname : "",
            title : "",
            tel : ""
        };
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }

    activeRegister = async () => {
        console.log(this.state);

        if (this.state.username.length == 0)
        {
            Swal.fire({
                icon: 'error',
                text: 'กรุณาระบุชื่อผู้ใช้งาน',
              })
            return              
        }

        if (this.state.password.length < 8)
        {
            Swal.fire({
                icon: 'error',
                text: 'รหัสผ่านต้องมีความยาว 8 ตัวอักษร',
              })
            return;
        }

        if (this.state.password != this.state.password_re)
        {
            Swal.fire({
                icon: 'error',
                text: 'รหัสผ่านที่ระบุมาไม่เหมือนกัน',
              })
            return;
        }



        let result = await registerUser(
            this.state.username, 
            this.state.password, 
            this.state.firstname,
            this.state.lastname,
            this.state.position,
            this.state.email,

        );


        console.log(result);
        
        if (result.status >= 200 && result.status <= 250)
        {
            await Swal.fire({
                icon: 'success',
                text: 'สร้างผู้ใช้งานสำเร็จ E-Mail สำหรับเข้าใช้งาน Google Service คือ ' + result.data.email,
            });
            this.props.history.push("/login");
        }
        else
        {
            await Swal.fire({
                icon: 'error',
                text: result.data.message
            });
        }
    }

    render() {


        return (
            <FrameHeader>
                <Card className="shadow">
                    <Card.Body>
                        <h4 className="text-center text-secondary">สมัครสมาชิก</h4>
                        <hr className="bg-secondary"></hr>
                        <div className="offset-md-2 col-md-8 offset-lg-3 col-lg-6">
                            <Form>
                                <Form.Group className="mb-1" controlId="frmRegisterUsername">
                                    <Form.Label>ชื่อผู้ใช้งาน</Form.Label>
                                    <Form.Control type="text" placeholder="" name="username" onChange={this.myChangeHandler}/>
                                </Form.Group>

                                <Form.Group className="mb-1" controlId="frmRegisterPassword">
                                    <Form.Label>รหัสผ่าน</Form.Label>
                                    <Form.Control type="password" placeholder="" name="password" onChange={this.myChangeHandler}/>
                                    <Form.Text className="text-muted">
                                    รหัสผ่านต้องมีความยาว 8 ตัวอักษร
                                    </Form.Text>

                                </Form.Group>
                                <Form.Group className="mb-1" controlId="frmRegisterPasswordRe">
                                    <Form.Label>ยืนยันรหัสผ่าน</Form.Label>
                                    <Form.Control type="password" placeholder="" name="password_re" onChange={this.myChangeHandler}/>
                                </Form.Group>
                            </Form>
                        </div>
                        <h4 className="text-center text-secondary mt-4">ข้อมูลส่วนตัว</h4>
                        <hr className="bg-secondary"></hr>
                        <div className="offset-md-2 col-md-8 offset-lg-3 col-lg-6">
                            <Form>


                                <Form.Group className="mb-1" controlId="frmRegisterFirstname">
                                    <Form.Label>ชื่อ</Form.Label>
                                    <Form.Control type="text" placeholder="" name="firstname" onChange={this.myChangeHandler}/>
                                </Form.Group>

                                <Form.Group className="mb-1" controlId="frmRegisterLastname">
                                    <Form.Label>นามสกุล</Form.Label>
                                    <Form.Control type="text" placeholder="" name="lastname" onChange={this.myChangeHandler}/>
                                </Form.Group>
                                <Form.Group className="mb-1" controlId="frmRegisterPosition">
                                    <Form.Label>ตำแหน่ง</Form.Label>
                                    <Form.Control type="text" placeholder="" name="position" onChange={this.myChangeHandler}/>
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="frmRegisterEmail">
                                    <Form.Label>อีเมล์</Form.Label>
                                    <Form.Control type="text" placeholder="" name="email" onChange={this.myChangeHandler}/>
                                </Form.Group>
                                <Form.Group className="text-center">
                                    <Button variant="secondary" type="button" className="btn-submit" onClick={this.activeRegister}>
                                        สมัครสมาชิก
                                    </Button>
                                </Form.Group>
                            </Form>
                        </div>
                    </Card.Body>
                </Card>
            </FrameHeader>

        )
    }
}

export default AuthRegisterFrm;