import axios from '../../config/axios';


const apiGetListCourseAssignmentFolder = async (course_id) => {

    try {
        const result = await axios.get(`/v1/course/${course_id}/assignment/folder`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiGetListCourseAssignment = async (course_id, page, limit, order_by, search_option) => {

    try {
        const result = await axios.get(`/v1/course/${course_id}/assignment?page=${page}&limit=${limit}&order_by=${order_by}&search_option=${search_option}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiGetListCourseAssignmentById = async (course_id, course_assignment_id) => {
    try {
        const result = await axios.get(`/v1/course/${course_id}/assignment/${course_assignment_id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiPostCourseAssignment = async (course_id, body) => {

    try {
        const result = await axios.post(`/v1/course/${course_id}/assignment`, body, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        },
        )
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiPostCourseAssignmentFolder = async (course_id, body) => {

    try {
        const result = await axios.post(`/v1/course/${course_id}/assignment_folder`, body, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        },
        )
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiDuplicateCourseAssignment = async (course_id, assignment_id, target_course_id) => {

    try {
        const result = await axios.post(`/v1/course/${course_id}/assignment/${assignment_id}/clone`, { target_course_id }, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        },
        )
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiPutCourseAssignment = async (course_id, course_assignment_id, body) => {

    try {
        const result = await axios.put(`/v1/course/${course_id}/assignment/${course_assignment_id}`, body, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        },
        )
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiPutCourseAssignmentFolder = async (course_id, course_assignment_folder_id, body) => {

    try {
        const result = await axios.put(`/v1/course/${course_id}/assignment_folder/${course_assignment_folder_id}`, body, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        },
        )
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}


const apiDeleteCourseAssignment = async (course_id, course_assignment_id) => {
    try {
        const result = await axios.delete(`/v1/course/assignment/${course_assignment_id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiDeleteCourseAssignmentFolder = async (course_id, course_assignment_folder_id) => {
    try {
        const result = await axios.delete(`/v1/course/${course_id}/assignment_folder/${course_assignment_folder_id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiUploadFileCourseAssignment = async (course_id, fileInput) => {
    try {

        let formData = new FormData()
        formData.append('filelist', '["tmp_file"]')
        formData.append('tmp_file', fileInput.files[0]);
        const result = await axios.post(`/v1/upload/course_content/${course_id}`, formData, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}


const apiPutCourseAssignmentMy = async (assignment_id, answer_data) => {
    try {

        let options = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        }

        const result = await axios.put(`/v1/course/assignment/my/${assignment_id}`, answer_data, options)

        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiPutEvaluateAssignment = async (course_id, course_assignment_id, payload) => {
    try {

        let options = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        }

        const result = await axios.put(`/v1/course/${course_id}/assignment/${course_assignment_id}/score`, payload, options)

        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiGetListCourseAssignmentStudent = async (course_id, page, limit, order_by, search_option) => {

    try {
        const result = await axios.get(`/v1/course/${course_id}/assignment/my?page=${page}&limit=${limit}&order_by=${order_by}]&search_option=${search_option}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiGetCourseAssignmentStudent = async (id) => {
    try {
        const result = await axios.get(`/v1/course/assignment/my/${id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiDowloadFileCourseContent = async (course_id,url) => {
    try {
        const result = await axios.get(`/v1/download_private/course_content/${course_id}/${url}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            responseType: 'blob',
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiDowloadFileAssignmentAttachment = async (assignment_id, idx, hash) => {
    try {
        const result = await axios.get(`/v1/download_private/assignment_attachment/${assignment_id}/${idx}/${hash}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            responseType: 'blob',
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}


const apiGetCourseAssignmentView = async (course_id,assignment_id,user_id) => {
    try {
        const result = await axios.get(`/v1/course/${course_id}/assignment/${assignment_id}/${user_id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}


const end_point = "http://api-uat.mu.skillmeo.com"

export {
    apiGetListCourseAssignment,
    apiGetListCourseAssignmentById,
    apiPostCourseAssignment,
    apiPostCourseAssignmentFolder,
    apiDuplicateCourseAssignment,
    apiPutCourseAssignment,
    apiPutCourseAssignmentFolder,
    apiPutCourseAssignmentMy,
    apiPutEvaluateAssignment,
    apiDeleteCourseAssignment,
    apiDeleteCourseAssignmentFolder,
    apiUploadFileCourseAssignment,
    apiGetListCourseAssignmentStudent,
    apiGetCourseAssignmentStudent,
    apiDowloadFileCourseContent,
    apiDowloadFileAssignmentAttachment,
    apiGetCourseAssignmentView,
    apiGetListCourseAssignmentFolder,
    end_point
}