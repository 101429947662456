import React, { Component }  from 'react'
import { Alert } from 'react-bootstrap';
import FrameHeader from '../frame/header';



class NotFound extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
        };
    }

    


    render() {


        console.log("NotFound render");

        return (
            <FrameHeader>
                <Alert variant="info">
                    เซิร์ฟเวอร์ไม่พบเว็บไซต์ที่ร้องขอ โปรดตรวจสอบ URL ที่ท่านพิมพ์เข้าสู่ระบบ
                </Alert>
            </FrameHeader>
        )
    }
}

export default NotFound;