import React, { Component }  from 'react'
import FrameHeader from '../frame/header'
import CompletedCourseDashboard from './completed_course'
import ActiveCourseDashboard from './active_course'
import ImageMapper from 'react-img-mapper';
import { apiGetAuthorizeCode } from '../../services/api/auth';
import { Alert, Card, Form, Button, ListGroup } from 'react-bootstrap';


class MyDashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            
        };
    }

    imgClick = async (area, index, event) => {
        console.log("area", area);
        console.log("index", index);

        if (area.id == "google_classroom")
        {
            window.open("https://classroom.google.com/", '_blank');
        }
        else if (area.id == "google_meet")
        {
            window.open("https://meet.google.com/", '_blank');
        }
        else if (area.id == "mooc")
        {
            let authorize_code = ( await apiGetAuthorizeCode(process.env.REACT_APP_MOOC_APP_CODE)).data.authorize_code;
            window.open( process.env.REACT_APP_MOOC_ENDPOINT + "?authorize_code=" + encodeURIComponent(authorize_code), '_blank');
        }
        else if (area.id == "skillmeo")
        {
            let authorize_code = ( await apiGetAuthorizeCode(process.env.REACT_APP_SKILLMEO_APP_CODE)).data.authorize_code;
            window.open( process.env.REACT_APP_SKILLMEO_ENDPOINT + "?authorize_code=" + encodeURIComponent(authorize_code), '_blank');
        }
    }


    render() {




        return (
            <FrameHeader fullscreen={true}>

                <div className="intro_container" style={{  "backgroundImage": 'url("/main_bg.png")'}}>
                    <div style={{width:"640px", marginTop : "128px", marginLeft: "20px"}}>
                        <Card className="shadow" style={{height:"340px"}}>
                            <Card.Body>
                                <ImageMapper src="/linked_platform.png" responsive={true} parentWidth={600} height={600}
                                map={{
                                    name : "linked_platform",
                                    areas : 
                                    [
                                        {
                                            id : "google_classroom",
                                            shape : "rect",
                                            coords : [17, 21, 251, 215]
                                        },
                                        {
                                            id : "google_meet",
                                            shape : "rect",
                                            coords : [275, 21, 469, 215]
                                        },
                                        {
                                            id : "mooc",
                                            shape : "rect",
                                            coords : [516, 21, 772, 215]
                                        },
                                        {
                                            id : "skillmeo",
                                            shape : "rect",
                                            coords : [502, 294, 772, 512]
                                        }
                                    ]
                                }}
                                onClick={this.imgClick}
                                ></ImageMapper>
                            </Card.Body>
                        </Card>
                    </div>
                </div>



            </FrameHeader>
        )
    }
}

export default MyDashboard;