import React, { Component }  from 'react'
import { Link } from 'react-router-dom'
import { apiLogout } from '../../services/api/auth'


class HeaderTop extends Component {

    constructor(props) {
        super(props);
        let user_info = null;
        if (localStorage.getItem("user_info"))
        {
            try
            {
                user_info = JSON.parse(localStorage.getItem("user_info"));
                console.log("user_info", user_info);
            }
            catch(e)
            {
                console.log("Error", e)
            }
            
        }
        this.state = {
            user_info : user_info
        };
    }

    
    logout = async () => {
        console.log("logout called");

        //await apiLogout();
        localStorage.removeItem('token');
        localStorage.removeItem('expire');
        localStorage.removeItem('user_info');
        setInterval(function(){
            window.location.replace('/login');
        }, 1000);
//        this.props.history.push('/login');
    }


    render() {




        return (
            <>
                <div className="d-flex justify-content-between" id="top-menu-container">
                    <div className="d-flex justify-content-start" id="top-menu-slogan-container">
                        <div id="top-menu-slogan-logo" className="m-0">
                            <img src="/blh_logo.png" style={{height:"80px"}} />
                        </div>
                    </div>
                    <div className="mr-4 d-flex flex-column justify-content-start" id="top-menu-user-info-container">
                        <div id="top-menu-bg"></div>
                        <div id="top-menu-user" className="pe-3 d-flex justify-content-end">
                            {
                                ( localStorage.getItem("token") && false)?
                                (
                                    <>
                                        <div className="mt-1 me-1">
                                            <img src={ this.state.user_info.pic_profile ? process.env.REACT_APP_STORAGE_ENDPOINT + "/" + this.state.user_info.pic_profile :  '/default_user.png'} id="top_user_img" ></img>
                                        </div>
                                        <div className="mx-3 mt-1">
                                            <a href="/app/dashboard" className="text-light fw-bold">หน้าแรก</a>
                                        </div>
                                        <div className="mx-3 mt-1">
                                            <a href="#" className="text-light fw-bold">คู่มือการใช้งาน</a>
                                        </div>
                                        <div className="mx-3 mt-1">
                                            <a href="#" className="text-light fw-bold">ติดต่อ</a>
                                        </div>
                                        <div className="mx-3 mt-1">
                                            <a href="#" onClick={ this.logout } className="link-secondary fw-bold txt-w">ออกจากระบบ</a>
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <div className="mt-1 mb-1 d-flex">
                                        <div class="mx-3">
                                            <a href="/login" className="text-light ">หน้าแรก</a>
                                        </div>
                                        <div class="mx-3">
                                            <a href="#" className="text-light ">คู่มือการใช้งาน</a>
                                        </div>
                                        <div class="mx-3">
                                            <a href="#" className="text-light ">ติดต่อ</a>
                                        </div>
                                    </div>
                                )
                            }


                        </div>

                    </div>
                </div>
            </>
        )
    }
}

export default HeaderTop;