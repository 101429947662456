import React, { Component } from 'react'
import FrameHeaderLeftMenu from '../frame/header_left_menu'
import { apiUploadFile } from '../../services/api/course'
import { apiEditProfile, apiGetInfo, apiEditUserPassword } from '../../services/api/user'
import { apiDeleteAuth } from '../../services/api/auth'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faInfoCircle } from '@fortawesome/free-solid-svg-icons'



import { Alert, Card, Form, Button, ListGroup } from 'react-bootstrap';
import Swal from 'sweetalert2'
import lodash from 'lodash'


class SettingUser extends Component {

    constructor(props) {
        super(props);

        let user_info = null;
        if (localStorage.getItem("user_info")) {
            try {
                user_info = JSON.parse(localStorage.getItem("user_info"));
                console.log("user_info", user_info);
            }
            catch (e) {

            }

        }
        this.state = {
            user_info: user_info,
            edit_user: {
                first_name: user_info.first_name,
                last_name: user_info.last_name,
                job_title: user_info.data.job_title,
                old_password: "",
                new_password: "",
                new_password_re: ""
            }
        };
    }


    updateProfilePicture = async () => {

        try {
            let upload_result = await apiUploadFile(document.getElementById('new_profile_pic').files[0]);
            console.log("upload_result", upload_result);


            lodash.cloneDeep(this.state.user_info)

            if (upload_result.status) {
                let store_at = upload_result.data.result.tmp_file;
                let updated_user = {
                    first_name: this.state.user_info.first_name,
                    last_name: this.state.user_info.last_name,
                    user_data: this.state.user_info.data,
                    pic_profile_url: store_at
                };
                await apiEditProfile(updated_user);

                let user_info = await apiGetInfo();
                localStorage.setItem("user_info", JSON.stringify(user_info.data));

                await Swal.fire({
                    icon: 'success',
                    text: "บันทึกข้อมูลเรียบร้อย"
                });

                window.location.reload();
            }
            else {
                await Swal.fire({
                    icon: 'error',
                    text: "เกิดข้อผิดพลาด บันทึกข้อมูลได้"
                });
            }
        }
        catch (e) {
            console.log(e);
            await Swal.fire({
                icon: 'error',
                text: "เกิดข้อผิดพลาด บันทึกข้อมูลได้"
            });
        }

    }

    updateProfile = async () => {
        try {
            console.log("this.state.edit_user", this.state.edit_user);

            let updated_user = {
                first_name: this.state.edit_user.first_name,
                last_name: this.state.edit_user.last_name,
                user_data: this.state.user_info.data,
            };
            updated_user.user_data.job_title = this.state.edit_user.job_title;
            console.log("updated_user", updated_user);
            await apiEditProfile(updated_user);

            let user_info = await apiGetInfo();
            localStorage.setItem("user_info", JSON.stringify(user_info.data));

            await Swal.fire({
                icon: 'success',
                text: "บันทึกข้อมูลเรียบร้อย"
            });

            window.location.reload();

        }
        catch (e) {
            console.log(e);
            await Swal.fire({
                icon: 'error',
                text: "เกิดข้อผิดพลาด บันทึกข้อมูลได้"
            });
        }
    }

    updatePassword = async () => {
        try {
            console.log("this.state.edit_user", this.state.edit_user);

            if (this.state.edit_user.old_password.trim().length == 0) {
                Swal.fire({
                    icon: 'error',
                    text: "กรุณาระบุรหัสผ่านเก่า"
                });
                return;
            }

            if (this.state.edit_user.new_password.trim().length == 0) {
                Swal.fire({
                    icon: 'error',
                    text: "กรุณาระบุรหัสผ่านใหม่"
                });
                return;
            }

            if (this.state.edit_user.new_password_re != this.state.edit_user.new_password) {
                Swal.fire({
                    icon: 'error',
                    text: "รหัสผ่านใหม่ที่ระบุมาไม่เหมือนกัน"
                });
                return;
            }

            let updated_user = {
                old_password: this.state.edit_user.old_password,
                new_password: this.state.edit_user.new_password,
            };
            console.log("updated_user", updated_user);
            await apiEditUserPassword(updated_user);

            let user_info = await apiGetInfo();
            localStorage.setItem("user_info", JSON.stringify(user_info.data));

            await Swal.fire({
                icon: 'success',
                text: "บันทึกข้อมูลเรียบร้อย กรุณาเข้าสู่ระบบใหม่"
            });

            window.location.reload();

        }
        catch (e) {
            console.log(e);
            await Swal.fire({
                icon: 'error',
                text: "เกิดข้อผิดพลาด รหัสผ่านไม่ถูกต้อง"
            });
        }
    }



    deleteUser = async () => {
        try {
            console.log("ลบข้อมูล", this.state.edit_user);

            Swal.fire({
                icon: 'warning',
                title: 'คุณต้องการจะลบบัญชีสมาชิกใช่หรือไม่?',
                showCancelButton: true,
                confirmButtonText: 'ใช่',
                cancelButtonText: 'ยกเลิก'

            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    let resultMyAuth = await apiDeleteAuth();
                    console.log("delete", resultMyAuth);
                    await Swal.fire({
                        icon: 'success',
                        text: "ลบบัญชีสมาชิกเรียบร้อย กรุณาเข้าสู่ระบบใหม่",
                        showConfirmButton: false,
                        timer: 1000
                    
                    });

                    localStorage.removeItem('token');
                    localStorage.removeItem('expire');
                    localStorage.removeItem('user_info');
                    setInterval(function(){
                        window.location.replace('/');
                    }, 500);
                }
            })


        }
        catch (e) {
            console.log(e);
            await Swal.fire({
                icon: 'error',
                text: "เกิดข้อผิดพลาด รหัสผ่านไม่ถูกต้อง"
            });
        }
    }


    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        let edit_user = this.state.edit_user;

        edit_user[nam] = val;

        this.setState({ edit_user: edit_user });
    }

    render() {




        return (
            <FrameHeaderLeftMenu active_menu='setting'>
                <Card className="bg-page text-white">
                    <Card.Body>
                        <h1 className='mb-0 title-page'>
                            ตั้งค่า
                        </h1>
                    </Card.Body>
                </Card>
                <h3 className="fw-bold mt-3 mb-3">
                    ข้อมูลส่วนตัว
                </h3>

                <Card className="">
                    <Card.Body>
                        <div className="offset-md-2 col-md-8 offset-lg-3 col-lg-6">
                            <div className="text-center mb-4">
                                <img src={this.state.user_info.pic_profile ? process.env.REACT_APP_STORAGE_ENDPOINT + "/" + this.state.user_info.pic_profile : '/default_user.png'} id="left_user_img" ></img>
                                <Form style={{ marginTop: '15px' }}>
                                    <Form.Group className="mb-1" controlId="new_profile_pic">
                                        <Form.Control className='form-input' type="file" placeholder="" />
                                    </Form.Group>
                                    <Form.Group className="text-center mt-3">
                                        <Button variant="secondary" type="button" className="btn-submit" onClick={this.updateProfilePicture}>
                                            บันทึกรูปภาพใหม่
                                        </Button>
                                    </Form.Group>
                                </Form>
                            </div>

                            <Form>
                                <div className="fw-bold mb-3">
                                    Username :: {this.state.user_info.user_method[0].username}
                                </div>
                                <Form.Group className="mb-1" controlId="frmRegisterFirstname">
                                    <Form.Label>ชื่อ</Form.Label>
                                    <Form.Control className='form-input' type="text" placeholder="" name="first_name"
                                        defaultValue={this.state.edit_user.first_name} onChange={this.myChangeHandler} />
                                </Form.Group>

                                <Form.Group className="mb-1" controlId="frmRegisterLastname">
                                    <Form.Label>นามสกุล</Form.Label>
                                    <Form.Control className='form-input' type="text" placeholder="" name="lastname"
                                        defaultValue={this.state.edit_user.last_name} onChange={this.myChangeHandler} />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="frmRegisterJobTitle">
                                    <Form.Label>ตำแหน่ง</Form.Label>
                                    <Form.Control className='form-input' type="text" placeholder="" name="job_title"
                                        defaultValue={this.state.edit_user.job_title} onChange={this.myChangeHandler} />
                                </Form.Group>
                                <Form.Group className="text-center">
                                    <Button variant="secondary" type="button" className="btn-submit" onClick={this.updateProfile}>
                                        บันทึกข้อมูล
                                    </Button>
                                </Form.Group>
                            </Form>

                        </div>
                    </Card.Body>
                </Card>
                <h3 className="fw-bold mt-3 mb-3">
                    เปลี่ยนรหัสผ่าน
                </h3>
                <Card className="">
                    <Card.Body>
                        <div className="offset-md-2 col-md-8 offset-lg-3 col-lg-6">

                            <Form>
                                <Form.Group className="mb-1" controlId="frmRegisterFirstname">
                                    <Form.Label>รหัสผ่านเดิม</Form.Label>
                                    <Form.Control className='form-input' type="password" placeholder="" name="old_password"
                                        onChange={this.myChangeHandler} />
                                </Form.Group>

                                <Form.Group className="mb-1" controlId="frmRegisterLastname">
                                    <Form.Label>รหัสผ่านใหม่</Form.Label>
                                    <Form.Control className='form-input' type="password" placeholder="" name="new_password"
                                        onChange={this.myChangeHandler} />
                                </Form.Group>
                                <Form.Group className="mb-4" controlId="frmRegisterJobTitle">
                                    <Form.Label>รหัสผ่านใหม่อีกครั้ง</Form.Label>
                                    <Form.Control className='form-input' type="password" placeholder="" name="new_password_re"
                                        onChange={this.myChangeHandler} />
                                </Form.Group>
                                <Form.Group className="text-center">
                                    <Button variant="secondary" type="button" className="btn-submit" onClick={this.updatePassword}>
                                        เปลี่ยนรหัสผ่าน
                                    </Button>
                                </Form.Group>
                            </Form>

                        </div>
                    </Card.Body>
                </Card>



                <h3 className="fw-bold mt-3 mb-3">
                    ลบบัญชี
                </h3>
                <Card className="">
                    <Card.Body>
                        <div className="offset-md-2 col-md-8 offset-lg-3 col-lg-6">
                            <Form>
                                <Form.Group className="text-center">
                                    <Button variant="danger" type="button" className="btn-submit" onClick={this.deleteUser}><FontAwesomeIcon icon={faInfoCircle} className="me-2" />ลบบัญชี</Button>
                                </Form.Group>
                            </Form>
                        </div>
                    </Card.Body>
                </Card>


            </FrameHeaderLeftMenu>
        )
    }
}

export default SettingUser;