import axios from '../../config/axios';

const apiGetUserList = async (page, limit, order_by, option) => {
  try {
    const result = await axios.get(`/v1/user`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      params : {
        page : page,
        limit : limit,
        order_by : JSON.stringify(order_by),
        search_option : JSON.stringify(option),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
};


const apiGetUserById = async (user_id) => {
  try {
    const result = await axios.get(`/v1/user/info/${user_id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
};


const apiEnableUser = async (id) => {
  // console.log(id)
  // console.log(localStorage.getItem("token"))
  try {
    const result = await axios.put(`/v1/user/enable/${id}`, '', {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiDisableUser = async (id) => {
  try {
    const result = await axios.put(`/v1/user/disable/${id}`, '', {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
};


const apiGetRoleList = async () => {
  try {
    const result = await axios.get(`/v1/user/role`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
};


const apiEditRole = async (id, role) => {
  try {
    const result = await axios.put(`/v1/user/role/${id}`, role, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}


const apiEditPassword = async (id, password) => {
  try {
    const result = await axios.put(`/v1/user/auth/${id}`, password, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiUpdateUser = async (uid, data) => {
  try {
    const result = await axios.put(`/v1/user/account/${uid}`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}


const apiAddUser = async (data) => {
  try {
    const result = await axios.post(`/v1/user`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiAdminResetPassword = async (payload) => {
  try {
    const result = await axios.put(`/v1/user/auth/` + payload.user_id + '/change_password', {}, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

export {
  apiGetUserList,
  apiEnableUser,
  apiDisableUser,
  apiGetRoleList,
  apiEditRole,
  apiEditPassword,
  apiUpdateUser,
  apiAddUser,
  apiGetUserById,
  apiAdminResetPassword
}