import axios from '../../config/axios';


const apiGetCourse = async (page, limit, orderby, option) => {

  try {
    const result = await axios.get(`/v1/course/my`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      params : {
        page : page,
        limit : limit,
        order_by : JSON.stringify(orderby),
        search_option : JSON.stringify(option)
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPublicCourse = async (page, limit, orderby, option) => {

  try {
    const result = await axios.get(`/v1/course/public`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      params : {
        page : page,
        limit : limit,
      order_by : JSON.stringify(orderby),
        search_option : JSON.stringify(option)
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}


const apiAllCourse = async (page, limit, orderby, option) => {

  try {
    const result = await axios.get(`/v1/course?page=${page}&limit=${limit}&order_by=${orderby}&search_option=${option}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiAdminAllCourse = async (page, limit, orderby, option) => {

  try {
    const result = await axios.get(`/v1/course`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      params: {
        page : page,
        limit : limit,
        order_by : JSON.stringify(orderby),
        search_option : JSON.stringify(option)
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}


const apiAddCourse = async (course) => {

  try {
    const result = await axios.post(`/v1/course`, course, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiGetCourseDetail = async (course_id) => {

  try {
    const result = await axios.get(`/v1/course/${course_id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiGetMyCourseDetail = async (course_id) => {

  try {
    const result = await axios.get(`/v1/course/my/${course_id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiGetPublicCourseDetail = async (course_id) => {

  try {
    const result = await axios.get(`/v1/course/public/${course_id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiEditCourse = async (course_id, data) => {


  try {
    const result = await axios.put(`/v1/course/${course_id}`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiUploadFile = async (file) => {

  try {

    let formData = new FormData()
    formData.append('filelist', '["tmp_file"]')
    formData.append('tmp_file', file);

    const result = await axios.post(`/v1/upload/public`, formData, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}


const apiAddTeacherToCourse = async (idcourse, idteacher) => {
  // console.log(id)
  try {

    const result = await axios.put(`/v1/course/${idcourse}/teachers`, idteacher, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiDelCourse = async (course_id) => {


  try {
    const result = await axios.delete(`/v1/course/${course_id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}


const apiGetTeachers = async (course_id) => {


  try {
    const result = await axios.get(`/v1/course/${course_id}/teachers`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPutTeachers = async (course_id, data) => {

  try {
    const result = await axios.put(`/v1/course/${course_id}/teachers`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPutAssistants = async (course_id, data) => {

  try {
    const result = await axios.put(`/v1/course/${course_id}/assistants`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPutStudents = async (course_id, data) => {

  try {
    const result = await axios.put(`/v1/course/${course_id}/students`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiDeleteTeacher = async (course_id, data) => {

  try {

    const result = await axios({
      method: 'DELETE',
      url: `/v1/course/${course_id}/teachers`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: data
    })

    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiDeleteAssistant = async (course_id, data) => {
  try {

    const result = await axios({
      method: 'DELETE',
      url: `/v1/course/${course_id}/assistants`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: data
    })

    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiDeleteStudent = async (course_id, data) => {

  try {
    const result = await axios({
      method: 'DELETE',
      url: `/v1/course/${course_id}/students`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: data
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiGetAssistants = async (course_id) => {

  try {
    const result = await axios.get(`/v1/course/${course_id}/assistants`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}


const apiGetStaffs = async (course_id) => {


  try {
    const result = await axios.get(`/v1/course/${course_id}/staffs`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiGetStudents = async (course_id) => {


  try {
    const result = await axios.get(`/v1/course/${course_id}/students`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}


const apiGetStudentsPortfolio = async (course_id, user_id) => {
  try {
    const result = await axios.get(`/v1/course/${course_id}/portfolio/${user_id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiGetMyPortfolio = async (course_id) => {
  try {
    const result = await axios.get(`/v1/course/my/${course_id}/portfolio`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiGetCourseGrading = async (course_id) => {
  try {
    const result = await axios.get(`/v1/course/${course_id}/grading`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPutCourseGrading = async (course_id, payload) => {
  try {
    const result = await axios.put(`/v1/course/${course_id}/grading`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}


const apiGetLogoCourse = async (course_id, url_img) => {


  try {
    const result = await axios.get(`v1/download_private/course_content/${course_id}/${url_img}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}


const apiEnrollCourse = async (course_id) => {


  try {
    const result = await axios.put(`v1/course/${course_id}/enroll`, {}, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}


export {
  apiGetCourse,
  apiAllCourse,
  apiAddCourse,
  apiGetCourseDetail,
  apiEditCourse,
  apiGetMyCourseDetail,
  apiUploadFile,
  apiAddTeacherToCourse,
  apiDelCourse,
  apiGetTeachers,
  apiGetAssistants,
  apiGetStaffs,
  apiGetStudents,
  apiGetStudentsPortfolio,
  apiGetMyPortfolio,
  apiGetLogoCourse,
  apiGetCourseGrading,
  apiPutCourseGrading,
  apiPutTeachers,
  apiPutAssistants,
  apiPutStudents,
  apiDeleteTeacher,
  apiDeleteAssistant,
  apiDeleteStudent,
  apiPublicCourse,
  apiGetPublicCourseDetail,
  apiEnrollCourse,
  apiAdminAllCourse
}