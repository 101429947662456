import React, { Component }  from 'react'
import FrameHeaderLeftMenu from '../frame/header_left_menu'
import lodash from 'lodash';
import { Alert, Card, Form, Button, ListGroup } from 'react-bootstrap';
import { apiPublicCourse } from '../../services/api/course';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'



class AllAvailableCourse extends Component {

    constructor(props) {
        super(props);

        this.state = {
            courses : [],
            user_info : {},
        };
    }

    loadPublicCourse = async() => {
        let course_data = await apiPublicCourse(1, 1000, [], []);
        
        this.setState({ courses : lodash.map(course_data.data.data, function(course) {
            return {
                id : course.id,
                course_name : course.course_name,
                course_logo : course.course_logo
            }
        }) });
    }

    componentDidMount() {
        this.loadPublicCourse();
        
    }

    render() {




        return (
            <FrameHeaderLeftMenu active_menu='course_list'>
                <Card className="bg-page text-white">
                    <Card.Body>
                        <h1 className='mb-0 title-page'>
                            หลักสูตรที่เปิดสอน
                        </h1>
                    </Card.Body>
                </Card>
                <div class="fw-bold mt-3 mb-2">
                    คอร์สเรียนทั้งหมด
                </div>
                <div className="d-flex mt-3 flex-wrap b-center">
                    {
                        this.state.courses.map((course, i) =>
                            <Card className="shadow list-course me-3 mb-3 dashboard-course" key={course.id}>
                                <div className="card-header" style={{
                                    ...(
                                        ( course.course_logo ) ?{
                                            backgroundImage : "url('" + process.env.REACT_APP_STORAGE_ENDPOINT + "/" + course.course_logo +"')",
                                            backgroundSize : "cover"
                                        }:
                                        {
                                            backgroundImage : "url('/top_logo.png')",
                                            backgroundSize : "contain"
                                        }
                                    )

                                }}>
                                </div>
                                <Card.Body className="p-2" >
                                    <Card.Text>
                                        <div className="text-secondary"> { course.course_name } </div>
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className="bg-white border-0 text-end">
                                    <a href={'/mooc/course/' + course.id + "/detail"}>
                                        <FontAwesomeIcon icon={faArrowRight} className="me-2"/>
                                    </a>
                                </Card.Footer>
                            </Card>
                        )
                    }



                </div>
            </FrameHeaderLeftMenu>
        )
    }
}

export default AllAvailableCourse;