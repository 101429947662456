import React, { Component } from 'react'
import FrameHeaderLeftMenu from '../frame/header_left_menu'
import lodash from 'lodash';
import { Alert, Card, Form, Button, ListGroup } from 'react-bootstrap';
import { apiGetPublicCourseDetail, apiEnrollCourse } from '../../services/api/course';
import { apiGetListCourseAssignmentFolder } from '../../services/api/assignment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'

class CourseDetail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            course: null,
            course_assignment_folders: [],
            course_status: 0 //0 = not register, 1 = learning, 2 = complete
        };
    }

    loadCourse = async () => {

        let course_data = await apiGetPublicCourseDetail(parseInt(this.props.match.params.course_id));
        let course_assignment_folders = await apiGetListCourseAssignmentFolder(parseInt(this.props.match.params.course_id));

        let my_info = JSON.parse(localStorage.getItem("user_info"));

        let course_status = 0;
        let is_attend = lodash.filter(course_data.data.course_student, { user_id: my_info.id });

        if (is_attend.length > 0) {
            course_status = 1;
            if (is_attend[0].is_course_complete === true) {
                course_status = 2;
            }
        }

        this.setState({
            course: course_data.data,
            course_status: course_status,
            course_assignment_folders: course_assignment_folders.data
        });
    }

    componentDidMount() {
        this.loadCourse();
    }

    enterCourse = () => {
        this.props.history.push("/mooc/course/" + this.props.match.params.course_id + "/learn");
    }

    enrollCourse = async () => {
        try {
            await apiEnrollCourse(this.props.match.params.course_id);
            await Swal.fire({
                icon: 'success',
                text: 'ลงทะเบียนเช้าเรียนในวิชานี้สำเร็จ',
            });

            this.enterCourse();
        }
        catch (e) {
            console.log(e);
            await Swal.fire({
                icon: 'error',
                text: "เกิดข้อผิดพลาด ไม่สามารถลงทะเบียนในวิชานี้ได้"
            });
        }
    }

    splitString = (text) => {
        const myArray = text.split(",");
        let result = "";
        console.log("arR", myArray)
        if (myArray.length) {

            for (let index = 0; index < myArray.length; index++) {
                result += "<p class='n-instructor'>" + myArray[index] + "</p>"
            }

        }
        document.getElementById('instructor-name').innerHTML = result;
        document.getElementById('instructor-name-top').innerHTML = result;
    }

    render() {




        return (
            <FrameHeaderLeftMenu active_menu='course_list'>
                <Card className="shadow">
                    <Card.Body className="course-detail-head">
                        <h2 className='text-b'>
                            {this.state.course && this.state.course.course_name}
                        </h2>
                        <h4 id="instructor-name-top" className="mt-3 text-m">
                            {this.state.course ?
                                this.splitString(this.state.course.course_data.course_instructor) : null
                            }
                            {/* {this.state.course && (this.state.course.course_data.course_instructor || "")} */}
                        </h4>
                        {
                            this.state.course &&
                            <img className="img-fluid" src={process.env.REACT_APP_STORAGE_ENDPOINT + "/" + this.state.course.course_data.cover_photo}></img>
                        }
                        {
                            (this.state.course_status == 0) &&
                            <div class="mt-4">
                                <Button className='btn-submit' variant="warning" type="button" onClick={this.enrollCourse}>
                                    เริ่มเรียน
                                </Button>
                            </div>
                        }
                        {
                            (this.state.course_status == 1) &&
                            <div class="mt-4">
                                <Button variant="warning" type="button" onClick={this.enterCourse} className="rounded-lg btn-submit">
                                    ศึกษาบทเรียนต่อ
                                </Button>
                            </div>
                        }
                        {
                            (this.state.course_status == 2) &&
                            <div class="mt-4">
                                <Button variant="warning" type="button" onClick={this.enterCourse} className="rounded-xl btn-submit">
                                    ศึกษาบทเรียนต่อ
                                </Button>
                                <div className="fs-xs mt-1 text-muted">*คุณเคยศึกษาบทเรียนนี้จนจบแล้ว</div>
                            </div>
                        }
                    </Card.Body>
                </Card>
                <div class="row mt-3">
                    <div className="col-md-7">
                        <Card className="shadow rounded-xl">
                            <Card.Body>
                                <div class="d-flex mb-3 align-self-center">
                                    {this.state.course && <img className="img-fluid rounded-circle inst-picture me-3" src={process.env.REACT_APP_STORAGE_ENDPOINT + "/" + this.state.course.course_data.photo_instructor}></img>}
                                    <div id='instructor-name' className='text-primary pt-3'>
                                        {this.state.course ?
                                            this.splitString(this.state.course.course_data.course_instructor) : null
                                        }
                                        {/* { this.state.course && this.state.course.course_data.course_instructor } */}
                                    </div>
                                </div>
                                <div class='text-primary'>

                                    {this.state.course && this.state.course.course_data.course_detail_1}
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="shadow mt-3 bg-primary rounded-xl">
                            <Card.Body className="text-light">
                                <h4 className='text-b'>รายละเอียด</h4>
                                <div>
                                    {this.state.course && this.state.course.course_data.description}
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-5">
                        <Card className="shadow border-primary border-2 rounded-xl">
                            <Card.Body>
                                <h4 className='mb-3 text-b'>เนื้อหาของคอร์สนี้</h4>
                                {
                                    this.state.course_assignment_folders.map((folder, i) =>
                                        <div className="p-2 text-primary">
                                            {folder.assignment_folder_name}
                                        </div>
                                    )
                                }

                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </FrameHeaderLeftMenu>
        )
    }
}

export default CourseDetail;