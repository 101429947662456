import axios from '../../config/axios';


const apiImportStudent = async (file) => {
    // console.log(file[0].name)
    var data = new FormData();
        data.append('excel_file', file[0],file[0].name);

    try {
        const result = await axios.post(`/v1/user/student/import_excel`, data ,{
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const apiImportInstructor = async (file) => {
    var data = new FormData();
        data.append('excel_file', file[0],file[0].name);

    try {
        const result = await axios.post(`/v1/user/instructor/import_excel`, data ,{
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        })
        return result
    } catch (error) {
        return error && error.response && error.response.data
    }
}

const registerUser = async (username, password, first_name, last_name, position, email) => {
    let payload = {
        "first_name" : first_name,
        "last_name" : last_name,
        "user_name" : username,
        "password" : password,
        "position" : position,
        "email" : email

    }

    try {
        const result = await axios.post(`/user/register_gmail`, payload ,{ })
        return result
    } catch (error) {
        return error && error.response
    }
}


export {
    apiImportStudent,
    apiImportInstructor,
    registerUser
  }