import React, { Component }  from 'react'
import FrameHeader from '../frame/header'
import lodash from 'lodash';
import { Alert, Card, Form, Button, ListGroup } from 'react-bootstrap';
import { apiGetMyCourseDetail, apiEnrollCourse } from '../../services/api/course';
import { apiGetListCourseAssignmentFolder } from '../../services/api/assignment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faQuestion, faStopCircle, faPauseCircle, faPlayCircle, faCheckCircle, faWindowMinimize, faFileSignature, faArrowCircleDown, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import CourseAssignmentVimeo from './assignment_vimeo';
import ModalAssignmentPDF from './assignment_modal_pdf';
import { useHistory } from "react-router-dom";

class CourseAssignment extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show_modal_assignment_pdf : false,
            active_assignment_pdf : null,
            course : null,
            course_assignment_folders : [],
            active_assignment_id : 0,
            active_video_assignment_id : 0,
            //active_quiz_assignment_id : 0,            
            active_folder_id : -1,
            show_folder_id : {},
            course_assignment_structs : {},
            course_assignment_folder_summaries : {}
        };
    }

    loadCourseAssignment = async(init_show_folder = false) => {

        let course_data = await apiGetMyCourseDetail(parseInt(this.props.match.params.course_id));
        let course_assignment_folders = await apiGetListCourseAssignmentFolder(parseInt(this.props.match.params.course_id));

        let course_assignment_structs = {};
        let show_folder_id = {};
        let course_assignment_folder_summaries = {};

        course_assignment_structs[0] = lodash.sortBy(lodash.filter(course_data.data.course_assignment, {course_assignment_folder_id : null}), ['assignment_order']);
        for (let i = 0 ; i < course_assignment_folders.data.length ; i++)
        {
            show_folder_id[course_assignment_folders.data[i].id.toString()] = false;
            course_assignment_structs[course_assignment_folders.data[i].id] = lodash.sortBy(lodash.filter(course_data.data.course_assignment, {course_assignment_folder_id : course_assignment_folders.data[i].id}), ['assignment_order']);
            course_assignment_folder_summaries[course_assignment_folders.data[i].id] = {
                video : lodash.filter(course_assignment_structs[course_assignment_folders.data[i].id], {assignment_type : "video"}).length,
                pdf : lodash.filter(course_assignment_structs[course_assignment_folders.data[i].id], {assignment_type : "pdf"}).length,
            }
        }
        console.log("course_assignment_structs", course_assignment_structs);
        this.setState({ 
            ...( init_show_folder ? { show_folder_id : show_folder_id } : {} ),
            course : course_data.data, 
            course_assignment_folders : course_assignment_folders.data,
            course_assignment_structs : course_assignment_structs,
            course_assignment_folder_summaries : course_assignment_folder_summaries
        });

    }

    componentDidMount() {
        this.loadCourseAssignment(true);
    }

    activeAssignment = async (assignment) => {

        console.log("active assignment", assignment);

        if (assignment.can_access === false)
            return;
        
        if (assignment.assignment_type == "quiz")
        {
            if (assignment.course_assignment_user_score.length > 0 && assignment.course_assignment_user_score[0].assignment_progr > 90.0)
            {
                Swal.fire({
                    icon: 'info',
                    text: 'คุณทำแบบทดสอบนี้ผ่านแล้ว',
                });
                return;
            }
            else
            {
                window.location.href = "/course/" + assignment.course_id + "/quiz/" + assignment.id;
                return;    
            }
        }
        
        if (assignment.assignment_type == "pdf")
        {
            this.setState({
                show_modal_assignment_pdf : true,
                active_assignment_pdf : assignment
            })
        }
        else
        {
            this.setState({
                show_modal_assignment_pdf : false,
                active_assignment_pdf : null
            })
        }
        let active_assignment_id = assignment.id;
        let active_video_assignment_id = assignment.assignment_type == "video" ? assignment.id : 0;
        //let active_quiz_assignment_id = assignment.assignment_type == "quiz" ? assignment.id : 0;
        let active_folder_id = assignment.course_assignment_folder_id || 0;

        this.setState({ 
            active_assignment_id : active_assignment_id, 
            active_video_assignment_id : active_video_assignment_id,
            //active_quiz_assignment_id : active_quiz_assignment_id,
            active_folder_id : active_folder_id
        });
        
        this.loadCourseAssignment();
    }

    renderFolder = (folder, idx) => {

        return <Card className={ this.state.active_folder_id == folder.id ? "ms-4 mt-2 bg-b2" : "ms-4 mt-2 bg-light" } key={idx}>
        <Card.Body>
            <div className="assignment-folder-idx">{idx}</div>
            <div className={ this.state.active_folder_id == folder.id ? "fw-bold ms-3" : "ms-3" }>
                { folder.assignment_folder_name }
            </div>
            {
                this.state.course_assignment_structs[folder.id] && 
                <Card className={ this.state.active_folder_id == folder.id ? "mt-2 bg-b3 ms-3" : "mt-2 bg-g1 ms-3" } >
                    <Card.Body className="p-1">
                        <div className="row">
                            <div className="col-10 d-flex mt-2 mb-2 div_assignment" onClick={ () => { this.setState({ show_folder_id : {...this.state.show_folder_id, ... { [folder.id.toString()] : !this.state.show_folder_id[folder.id.toString()] } } }) }}>
                                <div className="ms-4">
                                    <FontAwesomeIcon icon={faVideo} /> { this.state.course_assignment_folder_summaries[folder.id].video }
                                </div>
                                <div className="ms-3">
                                    <FontAwesomeIcon icon={faFileSignature} /> { this.state.course_assignment_folder_summaries[folder.id].pdf }
                                </div>

                            </div>
                            <div className="col-2 div_folder_state_icon">
                                { !this.state.show_folder_id[folder.id.toString()] && <FontAwesomeIcon icon={faArrowCircleRight} /> }
                                { this.state.show_folder_id[folder.id.toString()] && <FontAwesomeIcon icon={faArrowCircleDown} /> }

                            </div>
                        </div>
                        <div >

                        {
                            this.state.show_folder_id[folder.id.toString()] && this.state.course_assignment_structs[folder.id].map( (assignment) => 
                                <div className="d-flex mt-2 mb-2 div_assignment" key={assignment.id} variant={ assignment.can_access == false ? 'disable' : ( this.state.active_assignment_id == assignment.id ? 'active' : '' )} onClick={() => this.activeAssignment(assignment)}>
                                    <div className="div_learning_status text-center">

                                        {
                                            ( assignment.assignment_type != "pdf" && this.state.active_assignment_id == assignment.id) &&
                                            <FontAwesomeIcon icon={faPlayCircle} />
                                        }
                                        {
                                            ( assignment.assignment_type != "pdf" && this.state.active_assignment_id != assignment.id) &&
                                            <>
                                            {
                                                (
                                                    (assignment.course_assignment_user_score.length > 0 && assignment.course_assignment_user_score[0].assignment_progr > 0) &&
                                                        <FontAwesomeIcon icon={faPauseCircle} />
                                                )
                                            }
                                            {
                                                (
                                                    (assignment.course_assignment_user_score.length == 0 || assignment.course_assignment_user_score[0].assignment_progr == 0) &&
                                                        <FontAwesomeIcon icon={faStopCircle} />
                                                )
                                            }
                                            {
                                                (
                                                    (assignment.course_assignment_user_score.length > 0 && assignment.course_assignment_user_score[0].assignment_progr > 90) &&
                                                        <FontAwesomeIcon icon={faCheckCircle} />
                                                )
                                            }
                                            </>
                                        }
                                        
                                    </div>
                                    <div className="div_content_type text-center">
                                        {
                                            assignment.assignment_type == "quiz" && 
                                            <>
                                                <FontAwesomeIcon icon={faQuestion} />
                                            </>
                                        }
                                        {
                                            assignment.assignment_type == "video" && 
                                            <>
                                                <FontAwesomeIcon icon={faVideo} />
                                            </>
                                        }
                                        {
                                            assignment.assignment_type == "pdf" && 
                                            <>
                                                <FontAwesomeIcon icon={faFileSignature} />
                                            </>
                                        }
                                    </div>
                                    <div className="ms-2">
                                        {assignment.assignment_name}
                                    </div>
                                </div>
                            )
                        }
                        </div>

                    </Card.Body>
                </Card>
            }
        </Card.Body>
    </Card>
    }

    render() {




        return (
            <FrameHeader fullscreen={true}>
                <div class="container d-flex justify-content-between">
                    <div className="text-secondary">
                        <h4 className="fw-bold">{ this.state.course && this.state.course.course_name }</h4>
                    </div>
                    <div className="text-end">
                        { this.state.course && 
                            <>
                            {
                                this.state.course.course_student[0].is_course_complete === true &&
                                <span>ศึกษาวิชานี้สำเร็จแล้ว</span>
                            }
                            {
                                !this.state.course.course_student[0].is_course_complete &&
                                <span>{this.state.course.course_student[0].learning_progress}% กำลังศึกษาอยู่</span>
                            }
                            </>
                        }
                    </div>
                </div>
                <div id="video_container" className="text-center bg-b1">
                    <CourseAssignmentVimeo assignment_id={ this.state.active_video_assignment_id } />
                </div>

                <div class="container assignment_list_container">
                    {
                        this.state.course_assignment_folders.map( (folder, idx) => 
                            this.renderFolder(folder, idx+1)
                        )
                    }
                    {
                        //(this.state.course_assignment_structs[0] && this.state.course_assignment_structs[0].length > 0) &&
                        //    this.renderFolder({id : 0, assignment_folder_name : "เนื้อหาเพิ่มเติม" })
                    }

                </div>



                <ModalAssignmentPDF show={this.state.show_modal_assignment_pdf}
                    assignment={this.state.active_assignment_pdf}
                    handleClose={ () => { this.setState({show_modal_assignment_pdf : false}) } }

                /> 


                <div className='div-back-btn' onClick= { () => { window.location.replace("/mooc/course/" + this.state.course.id + "/detail")  }}>
                    Back
                </div>

            </FrameHeader>
        )
    }
}

export default CourseAssignment;
