import axios from '../../config/axios';


const apiGetInfo = async () => {
  try {
    const result = await axios.get(`/user/me`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiResetPassword = async (Values) => {
  try {
    const result = await axios.put(`/v1/user/auth/my`, Values, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}



const apiEditProfile = async (Values) => {
  try {
    const result = await axios.put(`/v1/user/account/my`, Values, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}


const apiAddUser = async (Values) => {
  try {
    const result = await axios.post(`/v1/user`, Values, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiEditUserPassword = async (Values) => {
    const result = await axios.put(`/v1/user/auth/my/password`, Values, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      }
    })
    return result
}

export {
  apiGetInfo,
  apiResetPassword,
  apiEditProfile,
  apiAddUser,
  apiEditUserPassword
}