import React, { Component }  from 'react'
import FrameHeader from '../frame/header'
import lodash from 'lodash';
import { Alert, Card, Form, Button, ListGroup } from 'react-bootstrap';
import { apiGetCourseAssignmentStudent, apiPutCourseAssignmentMy } from '../../services/api/assignment';
import Swal from 'sweetalert2'
import Vimeo from '@u-wave/react-vimeo';

class CourseAssignmentQuiz extends Component {

    constructor(props) {
        super(props);

        console.log("this.props", this.props);

        this.state = {
            assignment : null,
            answer : [],
            cur_idx : 0
        };
    }

    loadCourseAssignment = async() => {

        let assignment_res = await apiGetCourseAssignmentStudent(parseInt(this.props.match.params.course_assignment_id));
        
        let answer = [];

        for (let i = 0 ; i < assignment_res.data.assignment_data.exam.length ; i++)
        {
            answer.push(null);
        }

        if (assignment_res.data.assignment_type == 'quiz')
        {
            this.setState({
                assignment : assignment_res.data,
                answer : answer
            });
        }


    }

    componentDidMount() {
        this.loadCourseAssignment();
    }

    setAnswer = (sel_idx) => {
        let answer = this.state.answer;
        answer[this.state.cur_idx] = sel_idx;
        this.setState({answer : answer});
        console.log("answer", answer);
    }

    loadNext = () => {
        this.setState({cur_idx : this.state.cur_idx + 1});
    }

    loadPre = () => {
        this.setState({cur_idx : this.state.cur_idx - 1});
    }

    loadIdx = (idx) => {
        this.setState({cur_idx : idx});
    }

    back = () => {
        Swal.fire({
            icon: 'question',
            text: 'ยืนยันการละทิ้งการสอบ',
            showConfirmButton: false,
            showCancelButton: true,
            showDenyButton: true,
            denyButtonText: `ละทิ้งการสอบ`,
            cancelButtonText: `ทำข้อสอบต่อ`,
        }).then((result) => {
            if (result.isDenied)
            {
                window.location.href = "/course/" + this.props.match.params.course_id + "/detail";
            }
        });
    }

    submitAnswer = () => {
        Swal.fire({
            icon: 'question',
            text: 'ยืนยันการส่งข้อสอบ',
            showConfirmButton: true,
            confirmButtonText: `ส่งข้อสอบ`,
            showCancelButton: true,
            cancelButtonText: `ทำข้อสอบต่อ`,
        }).then((result) => {
            console.log(result);
            if (result.isConfirmed)
            {
                //send answer
                console.log("Submit Answer");
                apiPutCourseAssignmentMy(this.props.match.params.course_assignment_id, this.state.answer).then((assignment) => {
                    console.log(assignment);
                    if (assignment.assignment_progress != 100)
                    {
                        Swal.fire({
                            icon: 'error',
                            text: 'ขอแสดงความเสียใจ คุณสอบไม่ผ่าน โปรดทำข้อสอบใหม่อีกครั้ง',
                        }).then(() => window.location.href = "/course/" + this.props.match.params.course_id + "/quiz/" + this.props.match.params.course_assignment_id);
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'success',
                            text: 'ยินดีด้วยคุณสอบผ่านแล้ว',
                        }).then(() => window.location.href = "/course/" + this.props.match.params.course_id );
                    }
                });
            }
        });
    }
    render() {

        return (
            <FrameHeader>
            {
                this.state.assignment && 
                <div className="row">
                    <div className="col-sm-4 col-lg-3">
                        <Card className="mt-2 bg-light" >
                            <Card.Body className="p-1">
                                <div className="row">
                                {
                                    this.state.answer.map( (ans, i) => 
                                        <div className="col-4 ">
                                            <div onClick={() => this.loadIdx(i)} className={(Number.isInteger(this.state.answer[i]) ? 'bg-info text-center' : ' text-center bg-warning') + " p-1 m-1 " + ( (this.state.cur_idx == i) ? 'fw-bold text-secondary' : '')} style={{cursor:"pointer"}}>
                                                { i+1 }
                                            </div>
                                        </div>
                                    )
                                }
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-sm-8 col-lg-9">
                        <Card className="mt-2 bg-light" >
                            <Card.Body className="p-3">
                                <h4>{this.state.assignment.assignment_name}</h4>
                                <hr className="border-1 mt-5 mb-5"></hr>
                                <p className="fw-bold mt-3">
                                    { this.state.assignment.assignment_data.exam[this.state.cur_idx].title }
                                </p>
                                <p className="fw-bold mt-3">
                                    { this.state.assignment.assignment_data.exam[this.state.cur_idx].desc }
                                </p>
                                
                                {
                                    this.state.assignment.assignment_data.exam[this.state.cur_idx].type == "choice" &&
                                    <div key="default-radio" className="mt-5">
                                        {
                                            this.state.assignment.assignment_data.exam[this.state.cur_idx].choices.map( (choice, idx) => 
                                                <Form.Check 
                                                    type="radio"
                                                    name="user_choice"
                                                    label={choice}
                                                    className="mt-2 mb-2"
                                                    onChange={(e)=> this.setAnswer(idx) }
                                                    checked={this.state.answer[this.state.cur_idx] === idx}

                                                />
                                            )
                                        }
                                    </div>
                                }

                                <div className="row mt-5">
                                    <div class="col-lg-6">
                                        <Button variant="secondary" className="m-2" disabled={this.state.cur_idx === 0} onClick={this.loadPre}> ก่อนหน้า </Button>
                                        <Button variant="secondary" className="m-2" disabled={this.state.cur_idx === this.state.assignment.assignment_data.exam.length - 1} onClick={this.loadNext}> ถัดไป </Button>
                                    </div>
                                    <div class="col-lg-6 text-end">
                                        <Button variant="warning" className="m-2" onClick={this.back}>ละทิ้งการสอบ</Button>
                                        <Button variant="secondary" className="m-2" onClick={this.submitAnswer}>สิ้นสุดการสอบ</Button>

                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>

                
            }
            </FrameHeader>
        )
    }
}

export default CourseAssignmentQuiz;
