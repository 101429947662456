import React, { Component }  from 'react'
import { Link } from 'react-router-dom'
import HeaderTop from '../header/top'
import { Alert, Card, Form, Button, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faSchool, faWrench, faTable, faUsers } from '@fortawesome/free-solid-svg-icons'
import lodash from 'lodash';

class FrameHeaderLeftMenu extends Component {

    constructor(props) {
        super(props);

        let user_info = null;
        let is_admin = false
        if (localStorage.getItem("user_info"))
        {
            try
            {
                user_info = JSON.parse(localStorage.getItem("user_info"));
                console.log("user_info", user_info);

                let chk_role = lodash.filter(user_info.roles, { id : 1});
                if (chk_role.length > 0)
                    is_admin = true;
            }
            catch(e)
            {

            }
            
        }
        this.state = {
            user_info : user_info,
            is_admin : is_admin
        };
    }



    render() {




        return (
            <>
                <div className="container-fluid p-0">
                    <HeaderTop />
                </div>
                <div id="main-container-border">
                    <div id="main-container-border-inner" className="d-flex"> 
                        <div id="left-content">
                            <Card className="shadow">
                                <Card.Body>
                                    <div className="text-center">
                                        <img src={ this.state.user_info.pic_profile ? process.env.REACT_APP_STORAGE_ENDPOINT + "/" + this.state.user_info.pic_profile :  '/default_user.png'} id="left_user_img" ></img>
                                    </div>
                                    <div className="text-secondary text-center mt-3">
                                        { this.state.user_info.first_name } { this.state.user_info.last_name } 
                                    </div>
                                    <div className="text-secondary text-center mt-3">
                                        { this.state.user_info.data.job_title || "---" } 
                                    </div>
                                </Card.Body>
                            </Card>

                            <Card className="shadow mt-4" id="left_menu">
                                <Card.Body className="ps-0 pe-0 ">
                                    <ListGroup variant="flush">
                                        <ListGroup.Item action className={this.props.active_menu == 'dashboard' ? 'active' : ''}>
                                            <a href="/mooc/dashboard" className={this.props.active_menu == 'dashboard' ? 'text-light' : 'text-dark'}>
                                                <FontAwesomeIcon icon={faChartLine} className="me-2"/>
                                                Dashboard
                                            </a>
                                        </ListGroup.Item>
                                        <ListGroup.Item action className={this.props.active_menu == 'course_list' ? 'active' : ''}>
                                            <a href="/mooc/course/all" className={this.props.active_menu == 'course_list' ? 'text-light' : 'text-dark'}>
                                                <FontAwesomeIcon icon={faSchool} className="me-2"/>
                                                คอร์สเรียนทั้งหมด
                                            </a>
                                        </ListGroup.Item>
                                        <ListGroup.Item action className={this.props.active_menu == 'setting' ? 'active' : ''}>
                                            <a href="/mooc/setting" className={this.props.active_menu == 'setting' ? 'text-light' : 'text-dark'}>
                                                <FontAwesomeIcon icon={faWrench} className="me-2"/>
                                                ตั้งค่า
                                            </a>
                                        </ListGroup.Item>
                                    </ListGroup>
                                    {
                                        this.state.is_admin &&
                                        <ListGroup variant="flush" className="mt-4">
                                            <ListGroup.Item action className={this.props.active_menu == 'admin_course' ? 'active' : ''}>
                                                <a href="/admin/course" className={this.props.active_menu == 'admin_course' ? 'text-light' : 'text-dark'}>
                                                    <FontAwesomeIcon icon={faTable} className="me-2"/>
                                                    จัดการคอร์สเรียน
                                                </a>
                                            </ListGroup.Item>
                                            <ListGroup.Item action className={this.props.active_menu == 'admin_user' ? 'active' : ''}>
                                                <a href="/admin/user" className={this.props.active_menu == 'admin_user' ? 'text-light' : 'text-dark'}>
                                                    <FontAwesomeIcon icon={faUsers} className="me-2"/>
                                                    จัดการผู้ใช้งาน
                                                </a>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    }
                                </Card.Body>
                            </Card>

                        </div>
                        <div id="main-content" className="ps-3 pe-2">
                            { this.props.children }
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default FrameHeaderLeftMenu;