import React, { Component }  from 'react'
import FrameHeaderLeftMenu from '../../frame/header_left_menu'
import lodash from 'lodash';
import { Alert, Card, Form, Button, ListGroup, Table, FormGroup, Pagination } from 'react-bootstrap';
import { apiGetCourse, apiAddCourse, apiGetCourseDetail, apiUploadFile, apiEditCourse, apiDelCourse } from '../../../services/api/course';

import { apiGetListCourseAssignmentFolder, apiPostCourseAssignmentFolder, apiPutCourseAssignmentFolder, apiDeleteCourseAssignmentFolder, apiPostCourseAssignment, apiDeleteCourseAssignment } from '../../../services/api/assignment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import ModalAddAssignmentFolder from './modal_add_assignment_folder';
import ModalUpdateAssignmentFolder from './modal_update_assignment_folder';

class AdminCourseDetail extends Component {

    constructor(props) {
        super(props);
        console.log("this.props", this.props);

        this.state = {
            show_modal_create_assignment_folder : false,
            show_modal_update_assignment_folder : false,

            sel_assignment_folder : null,
            assignments : {},

            course_id : this.props.match.params.course_id,
            course : null,
            assignment_folders : [],
            new_assignments : {},
            assignments : [],
            edit_course : {
                course_name : null,
                photo_instructor : null,
                course_instructor : null,
                course_detail_1 : null,
                description : null,
                course_logo : null,
                cover_photo : null,
                is_available : null
            }
        };
    }

    myChangeCourseHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        let edit_course = this.state.edit_course;

        if (nam == 'is_available')
        {
            edit_course[nam] = event.target.checked;
        }
        else
        {
            edit_course[nam] = val;
        }

        this.setState({ edit_course : edit_course });
    }

    deleteCourse = async () => {
        if (window.confirm("ยืนยันการลบวิชานี้"))
        {
            let res = await apiDelCourse(this.state.course_id);
            await Swal.fire({
                icon: 'success',
                text: "ลบข้อมูลเสร็จสิ้น"
            });
            setInterval(function(){
                window.location.replace( '/admin/course' );
            }, 1000);
        }
    }

    updateCourse = async () => {
        try {
            let payload = {
                course_name : this.state.edit_course.course_name || this.state.course.course_name,
                course_logo : this.state.edit_course.course_logo || this.state.course.course_logo,
                course_is_allow_self_registration : this.state.edit_course.is_available ? true : false,
                course_is_publish : this.state.edit_course.is_available ? true : false,
                course_data : {
                    course_detail_1 : this.state.edit_course.course_detail_1 || this.state.course.course_data.course_detail_1,
                    description : this.state.edit_course.description || this.state.course.course_data.description,
                    cover_photo : this.state.edit_course.cover_photo || this.state.course.course_data.cover_photo,
                    photo_instructor : this.state.edit_course.photo_instructor || this.state.course.course_data.photo_instructor,
                    course_instructor : this.state.edit_course.course_instructor || this.state.course.course_data.course_instructor,

                }
            }

            console.log(payload);

            let res = await apiEditCourse(this.state.course_id, payload);
            Swal.fire({
                icon: 'success',
                text: "เพิ่มข้อมูลเสร็จสิ้น"
            });
            setInterval(function(){
                window.location.replace('/admin/course/' + res.data.id );
            }, 1000);
        }
        catch (e)
        {
            console.log(e);

        }
    }

    updatePicture = async (image_name) => {

        try
        {
            let upload_result = await apiUploadFile(document.getElementById(image_name).files[0]);
            console.log("upload_result", upload_result);
            let store_at = upload_result.data.result.tmp_file;
            let edit_course = this.state.edit_course;
            edit_course[image_name] = store_at;
            this.setState({ edit_course : edit_course });
        }
        catch(e)
        {
            console.log(e);
            await Swal.fire({
                icon: 'error',
                text: "เกิดข้อผิดพลาด บันทึกข้อมูลได้"
            });
        }
        
    }


    updateNewAssignment = async (assignment_folder_id, evt) => {


        let nam = evt.target.name;
        let val = evt.target.value;

        let new_assignments = this.state.new_assignments;

        console.log("new_assignments", new_assignments);
        console.log("assignment_folder_id", assignment_folder_id);
        
        if (nam == "url")
            new_assignments[assignment_folder_id.toString()]["assignment_data"][nam] = val;
        else
            new_assignments[assignment_folder_id.toString()][nam] = val;

        
        this.setState({ new_assignments : new_assignments });
        

    }

    addAssignment = async(assignment_folder_id) => {
        let new_assignment = this.state.new_assignments[assignment_folder_id.toString()];

        //check required are filled
        if ( !new_assignment.assignment_data.url || !new_assignment.assignment_name || !new_assignment.assignment_type || !new_assignment.assignment_data.url || !new_assignment.assignment_order)
        {
            alert("กรุณากรอกข้อมูลให้ครบถ้วน");
            return;
        }

        let res = await apiPostCourseAssignment(this.state.course_id, new_assignment);
        await Swal.fire({
            icon: 'success',
            text: "เพิ่มข้อมูลเสร็จสิ้น"
        });
        let course_id = this.state.course_id;
        setInterval(function(){
            window.location.replace( '/admin/course/' + course_id );
        }, 1000);


        

    }
    loadCourse = async() => {
        let course = await apiGetCourseDetail( this.state.course_id );
        if (course.data.course_data == null)
        {
            course.data.course_data = {
                photo_instructor : "",
                course_detail_1 : "",
                description : "",
                cover_photo : "",
                course_instructor : ""
            }
        }
        let edit_course = this.state.edit_course;

        edit_course.course_logo = course.data.course_logo;
        edit_course.cover_photo = course.data.course_data.cover_photo;
        edit_course.photo_instructor = course.data.course_data.photo_instructor;
        edit_course.course_is_publish = course.data.course_data.course_is_publish;
        let folders = await apiGetListCourseAssignmentFolder( this.state.course_id );
        
        let new_assignments = {};
        let assignments = {};

        for (let i = 0 ; i < folders.data.length ; i++)
        {
            new_assignments[folders.data[i].id.toString()] = {
                assignment_type : "video",
                assignment_data : {
                    url : null
                },
                assignment_prerequisite : [], 
                assignment_name : null, 
                assignment_score : null,
                assignment_order : null,
                assignment_folder_id : folders.data[i].id 
            }
            if (Array.isArray(course.data.course_assignment))
            {
                assignments[folders.data[i].id.toString()] = course.data.course_assignment.filter( (a) => {
                    return a.course_assignment_folder_id == folders.data[i].id;
                }).sort( (a, b) => { return a.assignment_order - b.assignment_order }); 
            }
            else
            {
                assignments[folders.data[i].id.toString()] = [];
            }
        }

        this.setState({ 
            course : course.data, 
            edit_course, 
            assignment_folders : folders.data,
            new_assignments : new_assignments,
            assignments : assignments
        });
    }


    componentDidMount() {
        this.loadCourse();
    }

    loadUpdateAssignmentFolderModal = (assignment_folder) => {
        this.setState({ 
            show_modal_update_assignment_folder : true,
            sel_assignment_folder : assignment_folder
        });
    }

    createAssignmentFolder = async (data) => {
        try
        {
            let res = await apiPostCourseAssignmentFolder(this.state.course_id, data);
            console.log("res", res);
            Swal.fire({
                icon: 'success',
                text: "เพิ่มข้อมูลเสร็จสิ้น"
            });
            setInterval(function(){
                window.location.reload(true);
            }, 1000);
        }
        catch(e)
        {
            console.log(e);
            await Swal.fire({
                icon: 'error',
                text: "เกิดข้อผิดพลาด บันทึกข้อมูลได้"
            });
        }
    }

    updateAssignmentFolder = async (data) => {
        try
        {
            let res = await apiPutCourseAssignmentFolder(this.state.course_id, this.state.sel_assignment_folder.id, data);
            console.log("res", res);
            Swal.fire({
                icon: 'success',
                text: "แก้ไขข้อมูลเสร็จสิ้น"
            });
            setInterval(function(){
                window.location.reload(true);
            }, 1000);
        }
        catch(e)
        {
            console.log(e);
            await Swal.fire({
                icon: 'error',
                text: "เกิดข้อผิดพลาด บันทึกข้อมูลได้"
            });
        }
    }

    deleteAssignment = async (assignment_id) => {
        try
        {
            if ( window.confirm("ยืนยันการลบข้อมูลเนื้อหา") )
            {
                let res = await apiDeleteCourseAssignment(this.state.course_id, assignment_id);
                console.log("res", res);
                Swal.fire({
                    icon: 'success',
                    text: "ลบข้อมูลเสร็จสิ้น"
                });
                setInterval(function(){
                    window.location.reload(true);
                }, 1000);
            }
        }
        catch(e)
        {
            console.log(e);
            await Swal.fire({
                icon: 'error',
                text: "เกิดข้อผิดพลาด บันทึกข้อมูลได้"
            });
        }
    }

    deleteAssignmentFolder = async (assignment_folder_id) => {
        try
        {
            if ( window.confirm("ยืนยันการลบข้อมูลกลุ่มของเนื้อหา") )
            {
                let res = await apiDeleteCourseAssignmentFolder(this.state.course_id, assignment_folder_id);
                console.log("res", res);
                Swal.fire({
                    icon: 'success',
                    text: "ลบข้อมูลเสร็จสิ้น"
                });
                setInterval(function(){
                    window.location.reload(true);
                }, 1000);
            }

            
        }
        catch(e)
        {
            console.log(e);
            await Swal.fire({
                icon: 'error',
                text: "เกิดข้อผิดพลาด บันทึกข้อมูลได้"
            });
        }
    }

    render() {

        return (
            <FrameHeaderLeftMenu active_menu='admin_course'>
                {
                    this.state.course ?
                    <>
                        <div className="row">
                            <div className="col-9">
                                <h3>
                                    { this.state.course.course_name }
                                </h3>
                            </div>
                            <div className="col-3 text-end">
                                <Button className='btn-submit' variant="danger" type="button" onClick={this.deleteCourse}>
                                    ลบหลักสูตร
                                </Button>
                            </div>
                        </div>

                        <Card className="mt-3">

                            <Card.Body>
                            <Card.Title>
                                ข้อมูลรายวิขา
                            </Card.Title>
                            <Form>

                                <Form.Group className="mb-1" >
                                    <Form.Label>ชื่อวิชา</Form.Label>
                                    <Form.Control className='form-input' type="text" placeholder="" name="course_name" 
                                    defaultValue={this.state.course.course_name} onChange={this.myChangeCourseHandler}/>
                                </Form.Group>

                                <Form.Group className="mb-1" >
                                    <Form.Label>ชื่อผู้สอน</Form.Label>
                                    <Form.Control as="textarea" style={{ height: '150px' }} className='form-input' type="text" placeholder="" name="course_instructor" 
                                    defaultValue={this.state.course.course_data.course_instructor} onChange={this.myChangeCourseHandler}/>
                                </Form.Group>
                                <Form.Group className="mb-4" >
                                    <Form.Label>คำอธิบายผู้สอน</Form.Label>
                                    <Form.Control className='form-input' type="text" placeholder="" name="course_detail_1" 
                                    defaultValue={this.state.course.course_data.course_detail_1} onChange={this.myChangeCourseHandler}/>
                                </Form.Group>
                                <Form.Group className="mb-4" >
                                    <Form.Label>คำอธิบายรายวิชา</Form.Label>
                                    <Form.Control className='form-input' type="text" placeholder="" name="description" 
                                    defaultValue={this.state.course.course_data.description} onChange={this.myChangeCourseHandler}/>
                                </Form.Group>

                                <Form.Group className="mb-4" >
                                    <Form.Label>URL ของรูปผู้สอน</Form.Label>
                                    <Form.Control className='form-input' type="file" placeholder="" name="photo_instructor" id="photo_instructor"
                                    onChange={() => { this.updatePicture( 'photo_instructor' )}}/>
                                    {
                                        this.state.edit_course.photo_instructor &&
                                        <img src={process.env.REACT_APP_STORAGE_ENDPOINT + "/" + this.state.edit_course.photo_instructor} style={{maxHeight : "100px" }} />
                                    }
                                </Form.Group>
                                <Form.Group className="mb-4" >
                                    <Form.Label>URL ของรูปภาพในหน้ารายละเอียดคอร์ส</Form.Label>
                                    <Form.Control className='form-input' type="file" placeholder="" name="cover_photo" id="cover_photo"
                                    onChange={() => { this.updatePicture( 'cover_photo' )}}/>
                                    {
                                        this.state.edit_course.cover_photo &&
                                        <img src={process.env.REACT_APP_STORAGE_ENDPOINT + "/" + this.state.edit_course.cover_photo} style={{maxHeight : "100px" }} />
                                    }
                                </Form.Group>
                                <Form.Group className="mb-4" >
                                    <Form.Label>URL ของรูปภาพในหน้า Dashboard และ คอร์สทั้งหมด</Form.Label>
                                    <Form.Control className='form-input' type="file" placeholder="" name="course_logo" id="course_logo"
                                    onChange={() => { this.updatePicture( 'course_logo' )}}/>
                                    {
                                        this.state.edit_course.course_logo &&
                                        <img src={process.env.REACT_APP_STORAGE_ENDPOINT + "/" + this.state.edit_course.course_logo} style={{maxHeight : "100px" }} />
                                    }
                                </Form.Group>
                                <Form.Group className="mb-4" >
                                    <Form.Label></Form.Label>
                                    <Form.Check 
                                        className='form-input'
                                        type='switch'
                                        label="เปิดให้ผู้เรียนเข้าเรียน"
                                        name='is_available'
                                        onChange={this.myChangeCourseHandler}
                                        defaultChecked={this.state.course.course_is_publish}
                                        value={true}
                                    />
                                </Form.Group>
                                <Form.Group className="text-center">
                                    <Button className="btn-submit" variant="secondary" type="button" onClick={this.updateCourse}>
                                        บันทึกข้อมูลหลักสูตร
                                    </Button>
                                </Form.Group>
                            </Form>
                            </Card.Body>
                        </Card>

                        <Card className="mt-3">

                            <Card.Body>
                                <Card.Title>
                                    ข้อมูลเนื้อหาหลักสูตร
                                </Card.Title>
                                <Button className="btn-submit" variant="primary" type="button" onClick={ () => { this.setState( { show_modal_create_assignment_folder : true } )}}>เพิ่มกลุ่มของเนื้อหา</Button>

                                {
                                    this.state.assignment_folders.map( (folder) => 
                                        <Card className="mt-3" key={folder.id}>
                                            <Card.Body>
                                                <Card.Title>
                                                    <div className="row">
                                                        <div className="col-9">
                                                            {folder.assignment_folder_name}
                                                        </div>
                                                        <div className="col-3 text-end">
                                                            <Button variant='danger' size="sm" onClick={() => { this.deleteAssignmentFolder(folder.id) }}>ลบ</Button>
                                                            <Button variant='warning' size="sm" onClick={() => {
                                                                this.loadUpdateAssignmentFolderModal(folder)
                                                            }}>แก้ไข</Button>
                                                        </div>
                                                    </div>
                                                </Card.Title>

                                                <Table striped bordered size="sm">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center" width="75">ลำดับ</th>
                                                            <th className="text-center" width="100">ประเภท</th>
                                                            <th className="text-center">ชื่อเนื้อหา</th>
                                                            <th className="text-center">URL</th>
                                                            <th className="text-center" width="50">---</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.assignments[folder.id].map( (assignment) => 
                                                            <tr>
                                                                <td>
                                                                    {assignment.assignment_order}
                                                                </td>
                                                                <td>
                                                                    {assignment.assignment_type}
                                                                </td>
                                                                <td>
                                                                    {assignment.assignment_name}
                                                                </td>
                                                                <td>
                                                                    {assignment.assignment_data.url}
                                                                </td>
                                                                <td className="text-center">
                                                                    <Button variant='danger' size="sm" onClick={() => { this.deleteAssignment(assignment.id) }}>ลบ</Button>
                                                                </td>
                                                            </tr>
                                                            
                                                            )
                                                        }
                                                        <tr>
                                                            <td>
                                                                <Form.Control type="number" size="sm" placeholder="" name="assignment_order" onChange={(evt) => { this.updateNewAssignment( folder.id, evt )}}/>
                                                            </td>
                                                            <td>
                                                                <Form.Select  size="sm" placeholder="" name="assignment_type" onChange={(evt) => { this.updateNewAssignment( folder.id, evt )}}>
                                                                    <option value="video">Video</option>
                                                                    <option value="pdf">PDF</option>
                                                                </Form.Select>
                                                            </td>
                                                            <td>
                                                                <Form.Control className='form-input' type="text" size="sm" placeholder="" name="assignment_name" onChange={(evt) => { this.updateNewAssignment( folder.id, evt )}}/>
                                                            </td>
                                                            <td>
                                                                <Form.Control className='form-input' type="text" size="sm" placeholder="" name="url" onChange={(evt) => { this.updateNewAssignment( folder.id, evt )}}/>
                                                            </td>
                                                            <td>
                                                                <Button className='btn-submit' variant='primary' size="sm" onClick={() => { this.addAssignment(folder.id) }}>เพิ่ม</Button>
                                                            </td>
                                                        </tr>
                                                    </tbody>

                                                </Table>
                                            </Card.Body>
                                        </Card>
                                    )
                                }
                            </Card.Body>
                        </Card>

                        <ModalAddAssignmentFolder 
                            show={this.state.show_modal_create_assignment_folder} 
                            handleClose={ () => { this.setState({show_modal_create_assignment_folder : false}) } }
                            modalSubmit={ (data) => { this.createAssignmentFolder(data) } }
                        />
                        <ModalUpdateAssignmentFolder 
                            show={this.state.show_modal_update_assignment_folder}
                            assignment_folder={this.state.sel_assignment_folder}
                            handleClose={ () => { this.setState({show_modal_update_assignment_folder : false}) } }
                            modalSubmit={ (data) => { this.updateAssignmentFolder(data) } }
                        />
                    </>
                    : <></>
                }


            </FrameHeaderLeftMenu>
        )
    }
}

export default AdminCourseDetail;