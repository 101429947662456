import React, { Component } from 'react'
import { Alert, Card, Form, Button, InputGroup } from 'react-bootstrap';
import FrameHeader from '../frame/header';
import Swal from 'sweetalert2'
import { apiLogin } from '../../services/api/auth'
import { apiGetInfo } from '../../services/api/user'
import './style/login.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faKey } from '@fortawesome/free-solid-svg-icons'

class AuthLoginFrm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: ""
        };
    }


    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }

    activeResetPassword = async () => {
        window.location.replace('/forgot_password');
    }
    
    activeLogin = async () => {
        console.log(this.state);

        if (this.state.username.length == 0)
        {
            Swal.fire({
                icon: 'error',
                text: 'กรุณาระบุชื่อผู้ใช้งาน',
              })
            return              
        }

        let payload = {
            "user_name" : this.state.username,
            "password" : this.state.password
        }
        let result = await apiLogin(payload);

        if (result.status >= 200 && result.status <= 250 && result.data.token)
        {
            localStorage.setItem("token", result.data.token);
            localStorage.setItem("expire", result.data.expire_at);

            let user_info = await apiGetInfo();
            localStorage.setItem("user_info", JSON.stringify(user_info.data));

            await Swal.fire({
                icon: 'success',
                text: 'เข้าสู่ระบบสำเร็จ',
            });

            setInterval(function(){
                window.location.replace('/app/dashboard');
            }, 1000);
        }
        else
        {
            if (result.is_found === null)
            {
                await Swal.fire({
                    icon: 'error',
                    text: "ผู้ใช้งานนีั้มีความพยายามเข้าระบบหลายครั้ง กรูณาทดลองเข้าระบบใหม่หลังจาก 5 นาที"
                });
            }
            else
            {
                await Swal.fire({
                    icon: 'error',
                    text: "ชื่อ หรือ รหัสผ่านไม่ถูกต้อง"
                });
    
            }

        }
    }

    render() {

        return (
            <FrameHeader fullscreen={true}>

                <div className="intro_container" style={{  "backgroundImage": 'url("main_bg.png")'}}>
                    <div className="row" style={{margin:'0px'}}>
                        <div className="col-12 offset-xl-1 col-xl-6 offset-lg-1 col-lg-8 offset-md-1 col-md-9 p-5">
                        <Card className="shadow">
                            <Card.Body>
                                <div className="row">
                                    <div className="col-6">
                                        <img src="/login_pic.png" className="image-fluid" />
                                    </div>
                                    <div className="col-6">
                                        <div className="text-end mb-4">
                                            New User? <a href="/register">Sign Up</a>
                                        </div>
                                        <h2 className="mb-0">Welcome Back!</h2>
                                        <h5 className="mt-0 mb-4">Login to continue</h5>
                                        <Form>
                                            <InputGroup className="mb-3" controlId="frmLoginUsername">
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faUser} />

                                                </InputGroup.Text>
                                                <Form.Control className='form-input' type="text" placeholder="" name="username" onChange={this.myChangeHandler} />
                                            </InputGroup>

                                            <InputGroup className="mb-3" controlId="frmLoginPassword">
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faKey} />

                                                </InputGroup.Text>
                                                <Form.Control className='form-input' type="password" placeholder="" name="password" onChange={this.myChangeHandler} />
                                            </InputGroup>


                                            <Form.Group className="text-left">
                                                <Button variant="primary" type="button" className="btn-submit me-3" style={{width: '100px', "borderRadius" : "25px" }} onClick={this.activeLogin}>
                                                    Login
                                                </Button>
                                                <Button variant="outline-secondary" type="button" className="btn-submit" style={{"borderRadius" : "25px"}} onClick={this.activeResetPassword}>
                                                    Forgot Password
                                                </Button>
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        </div>
                    </div>
                </div>

            </FrameHeader>

        )
    }
}

export default AuthLoginFrm;