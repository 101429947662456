import React, { Component }  from 'react'
import { Alert, Card, Form, Button } from 'react-bootstrap';
import FrameHeader from '../frame/header';
import Swal from 'sweetalert2'
import { apiCheckForgotPasswordToken, apiRedeemForgotPasswordToken } from '../../services/api/auth'
import isEmail from 'validator/lib/isEmail';


class AuthRedeemForgorPasswordTokenFrm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            token : this.props.match.params.token,
            is_token_valid : null,
            user : null,
            password : "",
            password_re : ""
        };
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }

    activeChangePassword = async () => {
        console.log(this.state);

        if (this.state.password.length < 8)
        {
            Swal.fire({
                icon: 'error',
                text: 'รหัสผ่านต้องมีความยาว 8 ตัวอักษร',
              })
            return;
        }

        if (this.state.password != this.state.password_re)
        {
            Swal.fire({
                icon: 'error',
                text: 'รหัสผ่านที่ระบุมาไม่เหมือนกัน',
              })
            return;
        }

        try
        {
            await apiRedeemForgotPasswordToken(this.state.token, this.state.password);
            await Swal.fire({
                icon: 'success',
                text: 'เปลี่ยนแปลงรหัสผ่านสำเร็จ',
            })
            this.props.history.push("/login");


        }
        catch(e)
        {
            console.log("e", e);
            await Swal.fire({
                icon: 'error',
                text: 'พบปัญหาไม่สามารถเปลี่ยนรหัสผ่านได้ กรุณาทดลองเปลี่ยนรหัสผ่านใหม่',
            })
        }
    }

    componentDidMount() {
        this.checkToken();
    }

    checkToken = async () => {
        try
        {
            let user = await apiCheckForgotPasswordToken(this.state.token);
            console.log(user);
            this.setState({is_token_valid : true, user : user})

        }
        catch(e)
        {
            console.error("e", e)
            this.setState({is_token_valid : false})
        }
    }

    render() {


        return (
            <FrameHeader>
                {
                    this.state.is_token_valid !== null &&
                    <Card className="shadow">
                        <Card.Body>
                            <h4 className="text-center text-secondary">ลืมรหัสผ่าน</h4>
                            <hr className="bg-secondary"></hr>
                            <div className="offset-md-2 col-md-8 offset-lg-3 col-lg-6">
                                {
                                    this.state.is_token_valid === true ?
                                    <Form>
                                        <Form.Group className="mb-3" controlId="frmRegisterPassword">
                                            <Form.Label>ชื่อผู้ใช้งาน</Form.Label>
                                            <Form.Control type="text" placeholder="" readOnly value={this.state.user.signon_method[0].user_name} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>รหัสผ่าน</Form.Label>
                                            <Form.Control type="password" placeholder="" name="password" onChange={this.myChangeHandler}/>
                                            <Form.Text className="text-muted">
                                                รหัสผ่านต้องมีความยาว 8 ตัวอักษร
                                            </Form.Text>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>ยืนยันรหัสผ่าน</Form.Label>
                                            <Form.Control type="password" placeholder="" name="password_re" onChange={this.myChangeHandler}/>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Button variant="secondary" type="button" className="" onClick={this.activeChangePassword}>
                                                เปลี่ยนรหัสผ่าน
                                            </Button>
                                        </Form.Group>
                                    </Form>
                                    :
                                    <Alert variant="secondary text-center">
                                        ลิงก์หมดอายุ หรือถูกใช้ในการเปลี่ยนรหัสผ่านไปแล้ว
                                    </Alert>
                                }
                            </div>
                        </Card.Body>
                    </Card>   
                }
            </FrameHeader>

        )
    }
}

export default AuthRedeemForgorPasswordTokenFrm;