import React, { Component }  from 'react'
import { Link } from 'react-router-dom'
import HeaderTop from '../header/top'


class FrameHeader extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    


    render() {




        return (
            <>
                <div className="container-fluid p-0" style={{zIndex : 100, position : "relative"}}>
                    <HeaderTop />
                </div>
                <div id="main-container-border">
                    <div id="main-container-border-inner"> 
                        <div className={this.props.fullscreen === true ? "container-fluid" : "container"}>
                            { this.props.children }
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default FrameHeader;