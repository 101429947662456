// src/routes.js
import React from 'react';
import { BrowserRouter } from 'react-router-dom'
import { Switch, Route } from "react-router";
import NotFound from './components/http_error/404'
import MyDashBoard from './components/dashboard/my'
import AuthLoginFrm from './components/auth/login_frm';
import AuthRegisterFrm from './components/auth/register_frm';
import SettingUser from './components/auth/setting';
import AllAvailableCourse from './components/course/all_available';
import CourseDetail from './components/course/detail';
import CourseAssignment from './components/course/assignment';
import CourseAssignmentQuiz from './components/course/assignment_quiz';
import AdminUserList from './components/admin/user/list';
import AdminCourseList from './components/admin/course/list';
import AdminCourseDetail from './components/admin/course/detail'
import AuthForgorPasswordFrm from './components/auth/forgot_password_frm'
import AuthRedeemForgorPasswordTokenFrm from './components/auth/redeem_forgot_password_token_frm'


import lodash from 'lodash';

const dayjs = require('dayjs')


export default () => {
    console.log("Router called..", localStorage.getItem("token"));

    //validate is token expired
    let expired_at = localStorage.getItem("expire");
    if (expired_at)
    {
        if ( dayjs().isAfter(dayjs(expired_at)) )
        {
            localStorage.removeItem('expire');
            localStorage.removeItem('token');
            localStorage.removeItem('user_info');

        }
    }
    else
    {
        localStorage.removeItem('expire');
        localStorage.removeItem('token');
        localStorage.removeItem('user_info');
    }

    let user_info = null;
    let is_admin = false
    if (localStorage.getItem("user_info"))
    {
        try
        {
            user_info = JSON.parse(localStorage.getItem("user_info"));
            console.log("user_info", user_info);

            let chk_role = lodash.filter(user_info.roles, { id : 1});
            if (chk_role.length > 0)
                is_admin = true;
        }
        catch(e)
        {

        }
        
    }


    return (
        <BrowserRouter>
            <Switch>

                {localStorage.getItem("token") ? (
                    <Switch>
                        <Route exact path={'/'} component={MyDashBoard} />
                        <Route exact path={'/app/dashboard'} component={MyDashBoard} />

                        <Route component={NotFound} />


                    </Switch>
                ) : (
                    <Switch>

                        <Route exact path={'/'} component={AuthLoginFrm} />
                        <Route exact path={'/login'} component={AuthLoginFrm} />
                        <Route exact path={'/register'} component={AuthRegisterFrm} />
                        <Route exact path={'/forgot_password'} component={AuthForgorPasswordFrm} />
                        <Route exact path={'/forgot_password/:token'} component={AuthRedeemForgorPasswordTokenFrm} />

                        <Route component={NotFound} />

                    </Switch>
                )}

            </Switch>
        </BrowserRouter>
    )

}