import React, { Component }  from 'react'
import FrameHeaderLeftMenu from '../../frame/header_left_menu'
import lodash from 'lodash';
import { Alert, Card, Form, Button, ListGroup, Table, FormGroup, Pagination, Modal } from 'react-bootstrap';
import { apiGetUserList } from '../../../services/api/admin';
import { apiGetListCourseAssignmentFolder } from '../../../services/api/assignment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'

class ModalAddAssignmentFolder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show_modal : this.props.show,
            modal_data : {
                assignment_folder_name : "",
                rank : ""
            }
        }
    }

        
    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        let modal_data = this.state.modal_data;

        modal_data[nam] = val;

        this.setState({ modal_data : modal_data });
    }

    handleClose = () => {
        this.props.handleClose()
    }

    modalSubmit = () => {
        this.props.modalSubmit(this.state.modal_data)
    }

    render() {



        return (
                <Modal show={this.props.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title >เพิ่มกลุ่มของเนื้อหา</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="frmRegisterFirstname">
                                <Form.Label>ชื่อกลุ่มของเนื้อหา</Form.Label>
                                <Form.Control className='form-input' type="text" placeholder="" name="assignment_folder_name" 
                                onChange={this.myChangeHandler}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="frmRegisterFirstname">
                                <Form.Label>ลำดับ</Form.Label>
                                <Form.Control className='form-input' type="number" min="0" max="1000" step="1" placeholder="" name="rank" 
                                onChange={this.myChangeHandler}/>
                            </Form.Group>
                            <Form.Group className="">
                                <Button variant="secondary" type="button" className="btn-submit" onClick={this.modalSubmit}>
                                    เพิ่ม
                                </Button>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                </Modal>
        )
    }
}

export default ModalAddAssignmentFolder;